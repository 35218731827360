<template>
  <div class="mb-6">
    <v-row align="center">
      <v-col cols="6"
        ><span class="text-nowrap">{{ totItems }} prodotti</span>
      </v-col>
      <v-col cols="6 text-right">
        <v-btn
          class="product-filters-btn-mobile px-2"
          text
          @click="dialog = true"
        >
          <v-icon>$filter</v-icon>
          <span class="text-underline">
            {{ $tc("filter.filters", getFacetsLength) }}</span
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      hide-overlay
      fullscreen
      class="facet-dialog"
    >
      <div class="product-facet-filter-mobile-container">
        <v-row class="top-bar d-flex justify-end align-center">
          <v-btn
            plain
            small
            @click="discard()"
            class="my-2 text-capitalize discard"
          >
            <span>{{ $t("filter.button.discard") }}</span>
            <v-icon small class="ml-1 filter-close">$close</v-icon>
          </v-btn>
        </v-row>
        <div class="list mt-5 mx-5">
          <ProductSortFilter
            class="mb-5"
            :sortFilter="sortFilter"
            @handleSortFilter="handleSortFilter"
          />

          <FilteredSelectFacets
            class="mb-5"
            v-for="(facet, index) in facetsFiltered"
            :key="facet.name"
            :index="index"
            :facet="facet"
            :facetsFilter="facetsFilter"
            :filteredProductsQty="productsQty"
            @handleFacetFilter="handleFacetFilter"
          />

          <div class="promo-filter-checkbox mb-5">
            <v-checkbox
              class="promo-checkbox mt-1 pt-2 rounded px-2"
              label="Promozione"
              false-value="false"
              true-value="true"
              hide-details
              v-model="promoFilter"
              @change="handlePromoFilter"
            >
            </v-checkbox>
          </div>
        </div>
        <div class="d-flex pa-2 bottom-bar justify-space-between">
          <v-btn
            v-on:click="clearAllFilters"
            depressed
            class="filter-button w-45"
          >
            {{ $t("filter.button.reset") }}
          </v-btn>
          <v-btn
            color="primary"
            class="w-45"
            v-on:click="applyFilters"
            depressed
            :loading="loadingApplyButton"
          >
            {{
              $tc("filter.button.applyShowAllProducts", filteredProductsCount)
            }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.product-facet-filter-mobile-container {
  $buttons-row-height: 60px;
  .top-bar {
    height: $buttons-row-height;
    .discard {
      font-size: 0.9rem !important;
    }
    .filter-close {
      font-size: 18px !important;
    }
  }
  .bottom-bar {
    height: $buttons-row-height;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .list {
    height: calc(100vh - (#{$buttons-row-height} * 2));
    overflow-y: auto;
    // .promo-filter-checkbox {
    // border: 2px solid grey;
    // }
    .filter-row {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        line-height: 3;
      }
    }
  }
}
</style>
<script>
import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
import FilteredSelectFacets from "@/components/product/filters/FilteredSelectFacets.vue";

import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductFacetFilterMobile",
  data() {
    return {
      dialog: false,
      panel: [],
      loadingApplyButton: false,
      initialFacets: {}
    };
  },
  components: {
    ProductSortFilter,
    FilteredSelectFacets
  },
  props: {
    facets: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filteredProductsCount: { type: Number, required: false },
    productsQty: { type: Number },
    hideFilterString: { type: String, default: "" },
    sortFilter: { type: Object },
    totItems: { type: Number }
  },
  computed: {
    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set(value) {
        //in questo caso non viene mai chiamato per il la checkbox p puntata a dei valori dell'array.
        this.$store.dispatch("category/setFilterFacetsMobile", {
          facets: value
        });
        global.EventBus.$emit("filterChanged");
      }
    },
    promoFilter: {
      get() {
        return this.storedPromoFilter;
      },
      set(promo) {
        this.$store.dispatch("category/setFilterPromo", promo);
        global.EventBus.$emit("filterChanged");
      }
    },
    newFilter: {
      get() {
        return this.storedNewFilter;
      },
      set(isNew) {
        this.$store.dispatch("category/setFilterNew", isNew);
        global.EventBus.$emit("filterChanged");
      }
    },
    getFacetsLength() {
      let facetsLength = 0;
      for (const prop in this.selectedFacetsXS) {
        facetsLength += this.selectedFacetsXS[prop].length;
      }
      return facetsLength;
    },
    facetsFiltered() {
      let vm = this;
      return vm.facets.filter(
        item => vm.hideFilterString.indexOf(item.name) < 0
      );
    }
  },
  methods: {
    handleFacetFilter(value) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: value
      });
      global.EventBus.$emit("filterChanged");
    },
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    },
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    applyFilters() {
      // memorizing last filters applied for future use in case of discard()
      this.initialFacets = cloneDeep(this.selectedFacetsXS);
      this.dialog = false;
      this.panel = [];
    },
    clearAllFilters() {
      this.$emit("handleFacetFilterMobile", {});
      this.initialFacets = {};
      this.dialog = false;
      this.panel = [];
    },
    discard() {
      this.$emit("handleFacetFilterMobile", this.initialFacets);
      this.dialog = false;
      this.panel = [];
    }
  },
  watch: {
    // retrieves filtered products quantity and sets text for Apply Button
    filteredProductsCount(val, oldVal) {
      if (val != oldVal) {
        this.loadingApplyButton = false;
      }
    }
  }
};
</script>
