<template>
  <div class="product-list-grid">
    <ProductFilters
      v-if="!hideSort && !hidePromoFilter"
      :facets="facets"
      :filteredProductsCount="filteredProductsCount"
      :hidePromoFilter="hidePromoFilter"
      :hideFilterString="hideFilterString"
      :hideSort="hideSort"
      :productsQty="products.length"
      :totItems="totItems"
    />
    <div class="product-count-find d-none d-sm-block my-5">
      <span
        v-if="totItems && !loading"
        v-html="$tc('products.countProductFind', totItems)"
      >
      </span>
    </div>
    <template v-if="totItems > 0">
      <div style="max-height:100%">
        <product-grid
          :productList="products"
          :key="key"
          :initPosition="(pager.selPage - 1) * pager.itemsPerPage"
          @selectProduct="handleClick"
        />
      </div>
      <v-btn
        block
        v-intersect="onIntersect"
        :loading="loading"
        @click="loadMore"
        v-if="$vuetify.breakpoint.xs && hasMoreItems"
      >
        <template v-if="hasMoreItems">{{ $t("products.showMore") }}</template>
        <template v-else>{{ $t("products.showAll") }}</template>
      </v-btn>
      <!-- v-model="page" -->
      <v-pagination
        v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
        :value="pageFilter"
        :page="pager.selPage"
        :length="pager.totPages ? pager.totPages : 0"
        :totalVisible="7"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
        class="py-6"
      ></v-pagination>

      <!-- @next="fetchProducts"
        @prev="fetchProducts"
        @input="fetchProducts" -->
    </template>
    <div v-else-if="loading" class="product-list-grid-skeleton">
      <v-row>
        <v-col
          v-for="index in 12"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-skeleton-loader
            type="image, list-item-three-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="py-8" v-else-if="promoFilter">
      Nessun prodotto in promozione
    </div>
    <div class="py-8" v-else-if="newFilter">Nessuna novità qui</div>
    <div class="py-8" v-else>Nessun prodotto trovato</div>
  </div>
</template>
<style lang="scss">
.product-count-find {
  min-height: 24px;
}
</style>
<script>
import ProductFilters from "@/components/product/filters/ProductFilters.vue";
import ProductGrid from "./ProductGrid.vue";
import AnalyticsService from "~/service/analyticsService";
import ProductService from "~/service/productService";

import { mapGetters } from "vuex";

export default {
  name: "ProductList",
  props: {
    parentCategoryId: { type: Number, required: false },
    categoryId: { type: Number, required: false },
    query: { type: String, required: false },
    barcode: { type: String, required: false },
    mode: { type: String, required: false },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false }
  },
  components: {
    ProductFilters,
    ProductGrid
  },
  jsonld() {
    if (this.products) {
      var items = this.products.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        url: process.env.VUE_APP_EBSN_URL + "/" + item.slug
      }));
      return {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: items
      };
    }
  },
  data() {
    return {
      products: [],
      facets: [],
      pager: {},
      page: 1,
      busy: false,
      loading: false,
      filteredProductsCount: 0,
      key: 0
    };
  },
  computed: {
    queryLabel() {
      return this.barcode ? this.barcode : this.query;
    },
    currentRouteName() {
      return this.$route.name;
    },
    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    },
    ...mapGetters({
      promoFilter: "category/promoFilter",
      newFilter: "category/newFilter",
      sortFilter: "category/sortFilter",
      pageFilter: "category/pageFilter"
    }),

    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set() {
        // this.$store.commit("app/updatedDrawerLeft", value);
      }
    },

    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    }
  },
  methods: {
    handleSortFilter(sort) {
      this.$store.dispatch("category/setFilterSort", sort);
      this.fetchProducts();
    },
    handlePromoFilter(promo) {
      this.$store.dispatch("category/setFilterPromo", promo);
      this.fetchProducts();
    },
    handleNewFilter(onlyNew) {
      this.$store.dispatch("category/setFilterNew", onlyNew);
      this.fetchProducts();
    },
    handleFacetFilter(filter) {
      this.$store.dispatch("category/setFilterFacets", {
        facets: filter.facets,
        parameterName: filter.parameterName
      });
      this.fetchProducts();
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: selectedFacetsXS
      });
      this.fetchProducts();
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchProducts();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },

    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetchProducts(true);
      }
    },
    async fetchProductsAndForce() {
      this.fetchProducts(false, true);
    },
    async fetchProducts(append, force = false) {
      this.loading = true;
      let response = await ProductService.search(
        {
          parent_category_id: this.parentCategoryId,
          category_id: this.categoryId || null,
          q: this.query,
          barcode: this.barcode,
          page: this.pageFilter,
          page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
          sort: this.sortFilter,
          promo: this.promoFilter,
          new_product: this.newFilter,
          force: force
        },
        this.facetsFilter
      );

      // Se ho letto il barcode e mi torna un Prodotto, navigo diretto al Dettaglio
      if (this.barcode && response.products.length > 0) {
        this.$router.push({
          name: "Product",
          params: { slug: response.products[0].slug }
        });
      } else if (append) {
        this.products = [...this.products, ...response.products];
      } else {
        this.products = response.products;
      }
      this.key++;

      this.pager = response.page;
      this.facets = response.facets;

      this.$emit("productsCount", this.pager.totItems);
      this.filteredProductsCount = response.page.totItems;
      if (this.pager.searchedText) {
        this.$emit("searchedText", this.pager.searchedText);
      }
      this.loading = false;
      AnalyticsService.viewProducts(
        response.products,
        this.itemListName || "ProductListGrid",
        this.pageFilter,
        this.query
      );
    },
    handleClick(payload) {
      AnalyticsService.clickProduct(
        payload.product,
        this.itemListName || "ProductListGrid",
        payload.index
      );
    }
  },
  async mounted() {
    if (this.$vuetify.breakpoint.xsOnly) {
      let page = this.pageFilter;
      for (let i = 1; i <= page; i++) {
        this.$store.dispatch("category/setFilterPage", i);
        await this.fetchProducts(true);
      }
    } else {
      this.fetchProducts();
    }
    global.EventBus.$on("filterChanged", this.fetchProductsAndForce);
  },
  beforeDestroy() {
    global.EventBus.$off("filterChanged");
  }
};
</script>
