<template>
  <div class="d-flex product-facet-filter align-center">
    <v-slide-group multiple show-arrows v-if="facetsFiltered">
      <v-slide-item class="pr-3 pt-1 mr-3">
        <v-checkbox
          class="promo-checkbox mt-1 pt-2 rounded px-2"
          label="Promozione"
          false-value="false"
          true-value="true"
          hide-details
          v-model="promoFilter"
          @change="handlePromoFilter"
        >
        </v-checkbox>
      </v-slide-item>
      <v-slide-item
        v-for="(facet, index) in facetsFiltered"
        :key="facet.name"
        class="pr-3 pt-1"
      >
        <FilteredSelectFacets
          :index="index"
          :facet="facet"
          :facetsFilter="facetsFilter"
          :filteredProductsQty="productsQty"
          @handleFacetFilter="handleFacetFilter"
        />
      </v-slide-item>
      <v-slide-item v-if="filteredProductsCount > 4" class="pt-1">
        <ProductSortFilter />
      </v-slide-item>
    </v-slide-group>
    <v-btn
      v-if="getFacetsLength > 0"
      text
      @click="resetFilters()"
      class="reset-filter-button ml-3"
    >
      {{ $t("filter.clear") }}
    </v-btn>
  </div>
</template>
<style lang="scss">
.product-facet-filter {
  .promo-checkbox {
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.42);
  }
  .v-icon.icon-next,
  .v-icon.icon-prev {
    width: 40px;
  }
}
</style>
<script>
import { mapGetters } from "vuex";

import FilteredSelectFacets from "@/components/product/filters/FilteredSelectFacets.vue";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductFacetFilterMobile",
  components: {
    FilteredSelectFacets,
    ProductSortFilter
  },
  data() {
    return {
      dialog: false,
      loadingApplyButton: false,
      initialFacets: {}
    };
  },
  props: {
    facets: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // facetsFilter: { type: Object },
    filteredProductsCount: { type: Number, required: false },
    hideFilterString: { type: String, default: "" },
    productsQty: { type: Number },
    sortFilter: { type: Object },
    totItems: { type: Number }
  },
  computed: {
    ...mapGetters({
      storedPromoFilter: "category/promoFilter"
      // storedNewFilter: "category/newFilter"
    }),
    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set(value) {
        this.$store.dispatch("category/setFilterFacetsMobile", {
          facets: value
        });
        global.EventBus.$emit("filterChanged");
      }
    },
    facetsFiltered() {
      let vm = this;
      return vm.facets.filter(
        item => vm.hideFilterString.indexOf(item.name) < 0
      );
    },
    promoFilter: {
      get() {
        return this.storedPromoFilter;
      },
      set(promo) {
        this.$store.dispatch("category/setFilterPromo", promo);
        global.EventBus.$emit("filterChanged");
      }
    },
    // newFilter: {
    //   get() {
    //     return this.storedNewFilter;
    //   },
    //   set(isNew) {
    //     this.$store.dispatch("category/setFilterNew", isNew);
    //     global.EventBus.$emit("filterChanged");
    //   }
    // },
    hasFacets() {
      return this.facets.length > 0;
    },
    getFacetsLength() {
      let facetsLength = 0;
      for (const prop in this.facetsFilter) {
        facetsLength += this.facetsFilter[prop].length;
      }
      return facetsLength;
    }
  },
  methods: {
    facetDisabled: facet => facet.filteredCount == 0,
    resetFilters() {
      global.EventBus.$emit("resetFilters");
      global.EventBus.$emit("filterChanged");
    },
    handleFacetFilter(value) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: value
      });
      global.EventBus.$emit("filterChanged");
    },
    handlePromoFilter(promo) {
      this.$emit("handlePromoFilter", promo);
    },
    // handleNewFilter(onlyNew) {
    //   this.$emit("handleNewFilter", onlyNew);
    // },
    applyFilters() {
      // memorizing last filters applied for future use in case of discard()
      this.initialFacets = cloneDeep(this.facetsFilter);
      this.dialog = false;
    },
    clearAllFilters() {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: {}
      });
      global.EventBus.$emit("filterChanged");
      this.initialFacets = {};
      this.dialog = false;
    },
    discard() {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: this.initialFacets
      });
      global.EventBus.$emit("filterChanged");
      this.dialog = false;
    }
  },
  watch: {
    // retrieves filtered products quantity and sets text for Apply Button
    filteredProductsCount(val, oldVal) {
      if (val != oldVal) {
        this.loadingApplyButton = false;
      }
    }
  }
};
</script>
