<template>
  <v-select
    v-model="selectedFacetsXS[facet.parameterName]"
    ref="selectsRef"
    :items="facet.values"
    :label="facet.name"
    append-icon="$expand"
    class="facet-filter facet-filter-select d-flex"
    background-color="#fff"
    outlined
    multiple
    solo
    clearable
    flat
    dense
    chips
    single-line
    hide-details
    deletable-chips
    item-value="id"
    height="36"
    @change="handleFacetFilter()"
  >
    <template #append-item>
      <div class="select-append-buttons d-flex justify-space-around">
        <v-btn outlined depressed color="primary" @click="closeSelect(true)">
          {{ $t("filter.button.reset") }}
        </v-btn>
        <v-btn depressed color="primary" @click="closeSelect(false)">{{
          $t("filter.button.apply")
        }}</v-btn>
      </div>
    </template>

    <template v-slot:selection="{ index }">
      <span
        class="filter-select-selection-slot"
        v-if="facetsFilter[facet.parameterName].length > 0 && index === 0"
      >
        {{ facet.name }}
        <v-chip pill x-small color="green" class="ml-auto rounded-pill">
          <span>{{ facetsFilter[facet.parameterName].length }}</span>
        </v-chip>
      </span>
    </template>

    <template v-slot:item="{ item, attrs }">
      <v-checkbox
        v-model="attrs.inputValue"
        dense
        hide-details
        class="select-filter-checkbox mt-0"
      ></v-checkbox>
      {{ item.name }}
      <v-chip pill x-small color="green" class="ml-auto rounded-pill">
        <span>{{ item.filteredCount }}</span>
      </v-chip>
    </template>
  </v-select>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "FilteredSelectFacets",
  data() {
    return {
      panelLength: 6
    };
  },
  props: {
    facet: { type: Object },
    facetsFilter: { type: Object },
    filteredProductsQty: { type: Number }
  },
  computed: {
    selectedFacetsXS() {
      return cloneDeep(this.facetsFilter);
    },
    filter() {
      console.log("filter", this.facet.values.slice(0, this.panelLength));
      return this.facet.values.slice(0, this.panelLength);
    },
    calcMaxHeight() {
      if (this.filteredProductsQty > 0 && this.filteredProductsQty <= 4) {
        return `${window.innerHeight - 278}px`;
      }
      return "100%";
    }
  },
  methods: {
    handleFacetFilter() {
      this.$emit("handleFacetFilter", this.selectedFacetsXS);
    },
    closeSelect(deleteFilter) {
      let selectRef = this.$refs.selectsRef;
      if (selectRef) {
        if (deleteFilter) {
          selectRef.resetValidation();
          selectRef.reset();
          this.$emit("handleFacetFilter", this.selectedFacetsXS);
        }
        selectRef.blur();
      }
    }
  },
  mounted() {}
};
</script>
