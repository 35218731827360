<template>
  <div class="product-grid mt-2 mt-sm-0">
    <h2 v-html="title" v-if="title"></h2>
    <v-row>
      <template v-for="(product, index) in products">
        <v-col
          :key="product.productId"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
          class="product-col"
        >
          <ProductCard
            v-bind:product="product"
            :position="initPosition + index"
            @selectProduct="
              $emit('selectProduct', {
                product: product,
                index: initPosition + index
              })
            "
          />
        </v-col>
        <v-col
          :key="product.productId + 'entity-relationship-prod'"
          v-if="product.entityRelationship && product.entityRelationship[1000]"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
          class="product-col entity-relationship-col  entity-relationship-prod"
        >
          <!-- entityReplationship connected to a product -->
          <RecipeCard :recipe="getRecipeFromProduct(product)" />
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
import RecipeCard from "../wordpress/RecipeCard.vue";
import ProductCard from "./ProductCard.vue";
import ProductService from "~/service/productService";
export default {
  components: { ProductCard, RecipeCard },
  name: "ProductGrid",
  props: {
    productList: { type: Array, required: false },
    categoryId: { type: Number, required: false },
    productId: { type: Number, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 12 },
    title: { type: String, required: false },
    proposal: { type: Object, required: false },
    paginationClass: { type: String, required: false },
    cols: { default: 1 },
    sm: { default: 2 },
    md: { default: 3 },
    lg: { default: 4 },
    xl: { default: 6 },
    showArrows: { type: Boolean, default: false },
    showBullets: { type: Boolean, default: true },

    initPosition: { type: Number, default: 0 }
  },

  data() {
    return {
      products: [],
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: `#product-list-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev-${this.paginationClass}`,
          nextEl: `#product-list-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1264: {
            slidesPerView: this.lg
          },
          1900: {
            slidesPerView: this.xl
          }
        }
      }
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchProducts();
  },
  computed: {
    hasFirstCardSlot() {
      return !!this.$slots.firstCard;
    }
  },
  methods: {
    getRecipeFromProduct(product) {
      if (product.entityRelationship && product.entityRelationship[1000]) {
        {
          let recipe = product.entityRelationship[1000][0];
          return {
            slug: recipe.slug,
            featured_image_url: recipe.img,
            title: { rendered: recipe.descr },
            showButton: true
          };
        }
      } else {
        return null;
      }
    },
    async fetchProducts() {
      if (this.productList) {
        this.products = this.productList;
      } else if (this.proposal) {
        let response = await ProductService.search({
          parent_category_id: this.proposal.categoryProposedId,
          page_size: this.limit
        });
        this.products = response.products;
      } else {
        let response = await ProductService.adv({
          layout: this.layout,
          product_id: this.productId,
          category_id: this.categoryId,
          limit: this.limit,
          shuffle: this.shuffle,
          promo: this.promo
        });
        this.products = response.products;
      }
    }
  }
};
</script>
