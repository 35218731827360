<template>
  <ProductFacetFilterMobile
    v-if="$vuetify.breakpoint.xs"
    :facets="facets"
    :facetsFilter="facetsFilter"
    :sortFilter="sortFilter"
    :filteredProductsCount="filteredProductsCount"
    :hideFilterString="hideFilterString"
    :totItems="totItems"
    :productsQty="productsQty"
  />

  <ProductFacetFilterDesktop
    v-else
    :facets="facets"
    :facetsFilter="facetsFilter"
    :hideFilterString="hideFilterString"
    :filteredProductsCount="filteredProductsCount"
    :totItems="totItems"
  />
</template>

<script>
import ProductFacetFilterMobile from "@/components/product/filters/ProductFacetFilterMobile.vue";
import ProductFacetFilterDesktop from "@/components/product/filters/ProductFacetFilterDesktop.vue";
export default {
  name: "ProductFilters",
  components: {
    ProductFacetFilterMobile,
    ProductFacetFilterDesktop
  },
  props: {
    promoFilter: { type: Boolean },
    newFilter: { type: Boolean },
    facets: { type: Array },
    facetsFilter: { type: Object },
    sortFilter: { type: Object },
    filteredProductsCount: { type: Number },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false },
    productsQty: { type: Number },
    totItems: { type: Number }
  }
};
</script>

<style></style>
