<template>
  <!-- :label="$vuetify.breakpoint.smAndDown ? $t('products.orderBy') : ''" -->
  <v-select
    v-model="sortFilter"
    class="product-sort-filter"
    color="primary"
    outlined
    :items="sortOptions"
    :label="$t('products.orderBy')"
    dense
    hide-details
    :class="$vuetify.breakpoint.xs ? 'mt-0' : 'pt-0'"
  >
    <template v-slot:append
      ><v-icon color="primary" class="d-none d-sm-flex"
        >$expand</v-icon
      ></template
    >
  </v-select>
</template>

<script>
export default {
  name: "ProductSortFilter",
  data() {
    return {
      sortOptions: [
        { value: "", text: "Rilevanza", icon: "ion-arrow-up-c" },
        { value: "alf_asc", text: "Descrizione", icon: "ion-arrow-up-c" },
        { value: "pri_asc", text: "Prezzo crescente", icon: "ion-arrow-up-c" },
        {
          value: "pri_des",
          text: "Prezzo decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "promo",
          text: "In promozione",
          icon: "ion-arrow-up-c"
        }
      ]
    };
  },
  computed: {
    sortFilter: {
      get() {
        return this.$store.state.category.filters.sort;
      },
      set(value) {
        this.$store.dispatch("category/setFilterSort", value);
        global.EventBus.$emit("filterChanged");
      }
    }
  }
};
</script>

<style lang="scss">
.product-sort-filter {
  .v-icon.v-icon.order-by-icon {
    font-size: 1rem;
    margin-top: 3px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-input__control {
      min-height: auto !important;
      .v-label {
        color: $text-color;
        letter-spacing: 1.1px;
      }
    }
  }
}
</style>
